import axios from "axios";
import * as state from "./dronos-state";
// import { API_URL } from "./constants";
import currentConfig from "./configurations";

const authToken = state.getDronosToken()?.value;
const workspaceId = state.getUserInfo()?.activeWorkspaceId;

function checkErrorCode(errorCode) {
  const unauthorizad = errorCode === 401;
  if (unauthorizad) {
    state.destroyDronosToken();
    window.location.href = "/";
  }
}

const axiosIns = axios.create({
  baseURL: currentConfig.apiUrl,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": `${window.location.origin}/`,
    // "Access-Control-Allow-Headers":
      // "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization",
    Authorization: authToken ? `Bearer ${authToken}` : "",
    workspaceId: workspaceId ?? '',
  },
  withCredentials: true,
});
axiosIns.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    checkErrorCode(error.response.status);
    return Promise.reject(error);
  }
);
export default axiosIns;
