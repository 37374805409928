// var latestToken = "2d0eb66c-4075-45c1-b9ee-b5a8707412f3"

const development = {
  superadmin: "https://api-superadmin.synapseai.cloud",
  auth: "https://api-auth.synapseai.cloud",
  project: "https://api-project.synapseai.cloud",
  profile: "https://api-profile.synapseai.cloud",
  s3: "https://be-testing-file.s3.ap-southeast-1.amazonaws.com/io1aWEhpcgMAY25Rf8y224/dw7bgp73uSq73wYNbY1aLh/cluster_datasets",
  s3Download: "https://api-s3.synapseai.cloud/download?key=",
  ai: "https://photov2.synapseai.cloud/fastapi",
  dashboard: "https://api-dashboard.synapseai.cloud",
  thermalImage: "https://api-thermal.synapseai.cloud/process",
};

const staging = {
  superadmin: "https://dronos-api-staging.aerodyne.group/super",
  auth: "https://dronos-api-staging.aerodyne.group/auth",
  project: "https://dronos-api-staging.aerodyne.group/project",
  profile: "https://dronos-api-staging.aerodyne.group/profile",
  s3: "https://be-testing-file.s3.ap-southeast-1.amazonaws.com/io1aWEhpcgMAY25Rf8y224/dw7bgp73uSq73wYNbY1aLh/cluster_datasets",
  s3Download:
    "https://api-s3.synapseai.cloud/download?bucket=be-staging-file&key=",
  ai: "https://photov2.synapseai.cloud/fastapi",
  dashboard: "https://dronos-api-staging.aerodyne.group/dashboard",
  thermalImage: "https://api-staging.synapseai.cloud/ai/thermal/process",
};

const staging_old = {
  superadmin: "https://api-staging.synapseai.cloud/super",
  auth: "https://api-staging.synapseai.cloud/auth",
  project: "https://api-staging.synapseai.cloud/project",
  profile: "https://api-staging.synapseai.cloud/profile",
  s3: "https://be-testing-file.s3.ap-southeast-1.amazonaws.com/io1aWEhpcgMAY25Rf8y224/dw7bgp73uSq73wYNbY1aLh/cluster_datasets",
  s3Download:
    "https://api-s3.synapseai.cloud/download?bucket=be-staging-file&key=",
  ai: "https://photov2.synapseai.cloud/fastapi",
  dashboard: "https://api-staging.synapseai.cloud/dashboard",
  thermalImage: "https://api-staging.synapseai.cloud/ai/thermal/process",
};

function dronosURL(currUrl) {
  let envURL = development;

  switch (currUrl) {
    case "development":
      envURL = development;
      break;
    case "staging":
      envURL = staging;
      break;
    case "staging_old":
      envURL = staging_old;
      break;
    default:
      envURL = development;
  }

  return envURL;
}

const liveURL = staging

export { dronosURL, liveURL };
