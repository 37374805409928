function delLocalStorage(key) {
	localStorage.removeItem(key);
}

function setLocalStorage(key, value) {
	const stringified = JSON.stringify(value);
	localStorage.setItem(key, stringified);
}

function getLocalStorage(key) {
	const item = localStorage.getItem(key);
	let parsed = null;
	if (item !== undefined) {
		parsed = JSON.parse(item);
	}

	return parsed;
}

const getDefaultState = () => ({
	organization: {},
	modulePermission: [
		"superadmin",
		"usermng.staff",
		"analytic.annotation",
		"dashboard",
		"usermng.client",
		"cluster",
		"report",
		"platform",
		"project",
		"analytic.validation",
	],
	roles: {
		default: [],
		list_id: [],
		list_name: [],
		isAdmin: false,
		isSuperAdmin: false,
		isValidator: false,
	},
	dronosToken: null,
	profile: {},
	tempVuex: {
		project_management: {
			project: null,
		},
	},
	mapbox: {
		map: null,
		mapboxgl: null,
	},
});

export let initialState = getDefaultState();

export function setDronosToken(data) {
	let token;
	if (data !== "" && data !== null && data !== undefined) {
		token = data;
	}
	setLocalStorage("dronos-token", token);
}

export function getDronosToken() {
	const token = getLocalStorage("dronos-token");
	return token;
}

export function destroyDronosToken() {
	delLocalStorage("dronos-token");
	delLocalStorage("user-data");
}

export function getUserInfo() {
	const userData = getLocalStorage("user-data");
	return userData;
}

// Get methods
export function getModulePermission() {
	const state = getLocalStorage("initialState");
	if (state !== null) {
		return state.modulePermission;
	} else {
		return initialState.modulePermission;
	}
}

export function getRoles() {
	const state = getLocalStorage("initialState");
	if (state !== null) {
		return state.roles;
	} else {
		return initialState.roles;
	}
}

export function getProfile() {
	const state = getLocalStorage("initialState");
	if (state !== null) {
		return state.profile;
	} else {
		return initialState.profile;
	}
}

export function getOrganization() {
	const state = getLocalStorage("initialState");
	if (state !== null) {
		return state.organization;
	} else {
		return initialState.organization;
	}
}

export function getTempVuex() {
	const state = getLocalStorage("initialState");
	if (state !== null) {
		return state.tempVuex;
	} else {
		return initialState.tempVuex;
	}
}

export function getMapbox() {
	return initialState.mapbox;
}

// Set Methods
export function setMapbox(map, mapboxgl) {
	initialState.mapbox = {
		map: map,
		mapboxgl: mapboxgl,
	};
}

export function assignTempProjectManagement(data) {
	let state = getLocalStorage("initialState");
	state.tempVuex.project_management = data;
	setLocalStorage("initialState", state);
}

export function assignUserData(data) {
	const allowedModule =
		data.organization.module === null ? [] : data.organization.module;
	const modulePermission = [];
	allowedModule.forEach((itemModule) => {
		modulePermission.push(itemModule.module_code);
	});

	const roles = data.role;
	const roleId = [];
	const roleName = [];
	roles.forEach((role) => {
		roleId.push(role.role_id);
		roleName.push(role.role_name);
	});

	initialState.roles.isAdmin = roleId.includes(2); // if user admin
	initialState.roles.isSuperAdmin = roleId.includes(1); // if user superadmin
	initialState.roles.isValidator = roleId.includes(78); // if user is validator

	initialState.organization = data.organization;
	initialState.roles.list_id = roleId;
	initialState.roles.list_name = roleName;

	initialState.userData = data;
	initialState.roles.default = roles;
	initialState.modulePermission = modulePermission;
	initialState.dronosToken = data.token;

	setLocalStorage("initialState", initialState);
}

export function setUserProfile(profile) {
	let state = getLocalStorage("initialState");
	if (state === null) {
		state = {};
	}
	state.profile = profile;
	setLocalStorage("initialState", state);
}

export function resetVuexState() {
	delLocalStorage("initialState");
	initialState = getDefaultState();
}

export function loadCss(app, fileName) {
	// console.log('testCss')
	return require(`./assets/${app}/css/${fileName}`);
}

export function getActiveWorkspaceEntity() {
	const userInfo = getUserInfo()
	return userInfo.member.find((item)=>item.workspace._id === userInfo.activeWorkspaceId)
}
